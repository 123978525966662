#examsList {
  display: flex;
  flex-direction: column;
  padding: 1px 32px;

  @media (width <= 860px) {
    padding: 1px 8px;
  }

  .free-test-container-body {
    height: 635px;
    overflow-y: auto;
  }

  .free-test-container-header {
    display: flex;
    justify-content: flex-start;
    gap: 3rem;
    background-color: white;
    //box-shadow: 0px 0px 27px rgb(0 0 0 / 15%);
    //margin-left: 6%;

    overflow-x: auto;
    padding-left: 36px;
    padding-right: 36px;
    border-bottom: 2px solid #ebebeb;

  }

  .box-shadow {
    box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.15);
  }

  .exam-icons {
    height: 36px;
  }

  .table-content {
    margin-top: 3px;
    padding-top: 0;
  }

  .type-exams {
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 12px;
    width: 220px;
    cursor: pointer;
    padding-bottom: 5px;

    &.selected {
      border-bottom: 2px solid #0249fb;
    }
  }

  .exam-name-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height, or 75% */

    @media (width <= 1024px) {
      font-size: 16px;
    }

    color: #181c32;
  }

  .second-section-test {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 7rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 4rem;

    text-align: center;

    background: linear-gradient(94.76deg, rgba(2, 73, 251, 0.9) 9.51%, rgba(1, 42, 146, 0.9) 91.12%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @media (width < 450px) {
      font-size: 36px;
    }

    @media (width <=1024px) {
      font-size: 7vw;
      margin-left: 15%;
      margin-right: 15%;
      line-height: 70px;
    }

    @media (width <=580px) {
      
      line-height: 50px;
      margin-top: 4rem;
    }
  }

  .free-test-container-body {
    height: 635px;
    overflow-y: auto;
  }

  .third-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .box-shadow {
    box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.15);
  }

  .examsList-slider-wrapper {
    position: relative;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 2em auto;
  }

  .sign-out {
    cursor: pointer;
  }

  .button-create-new-test {
    display: flex;
    justify-content: flex-end;
    margin-right: 48px;
  }

  .data-box {
    //max-width: 1350px;
    display: flex;
    gap: 24px;
   
    margin-bottom: 25px;
  }

  .performance-container {
    display: flex;
    flex-grow: 1;
  }

  .ranking-box {
    display: flex;
    justify-content: space-between;
    min-width: 34%;
    border-radius: 11px;
    background: white;
    height: 174px;
  }

  .title-performance,
  .title-ranking {
    display: flex;
    align-items: center;
  }

  .text-performace,
  .text-ranking {
    display: flex;
    flex-direction: column;
    margin-left: 18px;
  }

  .performance-data {
    display: flex;
    flex-direction: column;
  }

  .box-performance-data,
  .box-ranking-data {
    margin-top: 18px;
    margin-right: 24px;
    margin-bottom: 20px;
    margin-left: 50px;
  }

  .box-ranking-data {
    margin-left: 20px;
    margin-right: 20px;
  }

  .performance-graphic-icon,
  .ranking-icon {
    padding: 18px;
    background: #e7f6ff;
    border-radius: 11px;
  }
  .ranking-icon {
    background: #0249fb;
    padding: 12px;
  }
  .first-line-performance,
  .first-line-ranking {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
  }

  .second-line-performance,
  .second-line-ranking {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    color: #494949;
  }

  .performance-data,
  .ranking-data {
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-top: 25px;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 500;
    color: #b5b5c3;
  }

  .third-line-ranking {
    font-family: 'Poppins';
    font-size: 19px;
    font-weight: 600;
    color: #b5b5c3;
  }

  .happy-student-image {
    margin-right: 48px;
    margin-top: -38px;
    scale: 0.87;
  }
  .ranking-image {
    width: 38%;
    display: flex;
    justify-content: center;
    align-items: baseline;
  }
  .ranking-student-image {
    margin-top: 7px;
    margin-right: 30px;
    scale:1.07;
  }


}
