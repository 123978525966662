.modal-payment {
  .modal-content {
    @media (width >= 1280px) {
      width: 1000px;
    }
  }
  .modal-body {
    //height: 545px;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    //align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 10px;

    @media (width >= 1280px) {
      width: 995px;
    }

    @media (width < 1280px) {
      //width: 625px;
      height: 660px;
    }

    .body-text {
      color: #0249fb;
      font-weight: 600;
      font-size: 1.1rem;
      margin-bottom: 2px;
      margin-top: 25px;
      display: flex;
      justify-content: center;
      //text-transform: uppercase;

      span {
        text-align: center;
      }
    }

    #paymentplans {
      scale: 0.77;
      display: flex;

      .plan-box {
        margin-left: -142px;
        margin-top: -45px;
        //padding-right: 20px;

        section:last-child {
          margin-right: 40px;
        }
      }

      .plan-box-2 {
       // margin-right: 40px;
      }
    }

    .title {
      color: #20d489;
      text-align: center;
      font-size: 26px;
      font-weight: 500;
    }

    .content {
      margin-bottom: 30px;
      font-size: 15px;
      text-align: center;
    }
  }

  .modal-header {
    border-bottom: none;
    margin-bottom: -35px;
    button {
      padding-right: 20px;
      padding-top: 20px;
    }
  }
}
