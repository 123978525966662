#exam-answers {
  padding: 1px 50px;

  .exam-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    margin-top: 29px;
    margin-bottom: 18px;
  }

  .header-elements {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .exam-title {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
  }

  .difficulty {
    display: flex;
    gap: 6px;
    background-color: #f3f6f9;
    padding: 12px 14px 12px 14px;
    border-radius: 11px;
  }

  .difficulty-text {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    color: #b5b5c3;
  }

  .difficulty-level {
    display: flex;
    align-items: center;
    scale: 0.9;
    gap: 2px;
    color: #ffc700;
  }
  .button-submit {
    font-size: 12px;
  }

  .exam-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 100%;
    margin-top: 8px;
  }

  .exam-questions {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    //min-width: 500px;
    //flex-grow: 1;
    gap: 12px;
    overflow-y: scroll;
    height: calc(100vh - 215px);

    > div {
      //background: white;
      border-radius: 11px;
    }
  }

  .answer-card {
    min-width: 45%;
    //width: 1000px;
    max-height: calc(100vh - 215px);
    overflow: auto;
  }

  .exam-time {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 43px;
    padding: 0 15px;
    background-color: #f3f6f9;
    border-radius: 11px;
  }

  .text-time {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    color: #0249fb;
  }

  .official {
    font-weight: bold;
  }

  .subject-container-parent {
    //padding: 25px 0 10px 0px;
/*     display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */

    .header-box {
      //border-bottom: 0.1px solid #ecf0f3;
      padding-bottom: 20px;
      padding-top: 20px;
      //padding: 30px;
      //width: calc(100% - 30px);
    }

    .header-span {
      font-size: 16px;
      margin-left: 30px;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .icon-play {
    padding-right: 8px;
    cursor: pointer;
    //border-right: 2px solid #ecf0f3;
  }

  .report-icon {
    padding: 12px;
    background-color: #f3f6f9;
    border-radius: 11px;
    cursor: pointer;
  }
  .report-img {
    scale: 0.89;
  }

  .question-box {
    display: flex;
    flex-direction: row;
    //background-color: #F9F9F9;
    background-color: white;
    margin: 25px;
    padding: 0px 10px 10px 10px;

    .question-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px;
      min-width: 150px;
      border-right: 0.1px solid #ecf0f3;
      font-size: 15px;
      font-weight: 600;
    }

    .question-image {
      display: flex;
      width: 50%;
      flex-direction: column;
      justify-content: center;
      padding: 1px;
      border-right: 0.1px solid #ecf0f3;

      img {
        max-width: 100%;
      }
    }

    .answer-box {
      display: flex;
      justify-content: space-around;
      padding: 40px 0; 
      width: 100%;
      max-width: 450px;
      //gap: 35%;
  
      .answer-title {
        font-weight: 700;
      }

      .option-row {
        display: flex;
        margin-top: 5px;

        img {
          margin-right: 6px;
        }
      }
    }
  }

  .magnifying-glass-icon {
    justify-content: flex-end;
    display: flex;
    cursor: pointer;
    margin: 12px 15px 0 10px;
  }

  .icon-pause {
    color: #0249fb;
    cursor: pointer;
    font-size: 16px;
  }

  .watch-box {
    border-left: 2px solid #ecf0f3;
    display: flex;
    align-items: center;
  }

  .stopwatch {
    span {
      font-family: 'Poppins';
      font-size: 14px;
      font-weight: 400;
      color: #0249fb;
    }
  }
}
