#exam-perform {
  padding: 0 21px;
  margin-top: -20px;
  min-width: 950px;

  embed {
    body {
      //pointer-events: none;
    }
  }

  .exam-top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    margin-top: 29px;
    margin-bottom: 25px;
  }

  .header-elements {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .exam-title {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
  }

  .difficulty {
    display: flex;
    gap: 6px;
    background-color: #f3f6f9;
    padding: 12px 14px 12px 14px;
    border-radius: 11px;
  }

  .difficulty-text {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    color: #b5b5c3;
  }

  .difficulty-level {
    display: flex;
    align-items: center;
    scale: 0.9;
    gap: 2px;
    color: #ffc700;
  }
  .button-submit {
    font-size: 12px;
  }
  .button-back {
    font-size: 12px;
    width: 68px;
    position: relative;

    svg {
      font-size: 20px;
      left: 6px;
      top: 10px;
      position: absolute;
    }
    span {
      margin-right: -15px;
    }
  }

  .exam-container {
    display: flex;
    gap: 16px;
    max-width: 100%;
    margin-top: 8px;
  }


  .exam-questions {
    display: flex;
    flex-direction: column;
    min-width: calc(55% - 9px);
    //min-width: 500px;
    //flex-grow: 1;
    gap: 12px;
    overflow-y: scroll;
    height: calc(100vh - 170px);

    > div {
      background: white;
      border-radius: 11px;
    }
  }

  .answer-section-container {
    min-width: 45%;
    //width: 1000px;
    max-height: calc(100vh - 170px);
    overflow: auto;
  }

  .question-header-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-bottom: 0.1px solid #ecf0f3;
    font-size: 15px;
    font-weight: 600;

    span {
      padding: 21px;
    }
  }

  .question-number {
    color: #b5b5c3;
  }

  .text-difficulty {
    display: flex;
    gap: 4px;
    color: #ffc700;
    //scale:0.85;

    .faded-star {
      color: #b5b5c3;
    }
  }

  .exam-time {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 43px;
    padding: 0 15px;
    width: 90px;
    background-color: #f3f6f9;
    border-radius: 11px;
  }

  .text-time {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    color: #0249fb;
  }

  .subject-container-parent {
    .header-box {
      border-bottom: 0.1px solid #ecf0f3;
      padding-bottom: 20px;
      padding-top: 20px;
    }

    .header-span {
      font-size: 16px;
      margin-left: 20px;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .icon-play {
    padding-right: 8px;
    cursor: pointer;
  }

  .report-icon {
    padding: 12px;
    background-color: #f3f6f9;
    border-radius: 11px;
    cursor: pointer;
  }
  .report-img {
    scale: 0.89;
  }

  .question-box {
    display: flex;
    flex-direction: column;
  }

  .question-image {
    display: flex;
    justify-content: flex-start;
    padding: 15px 2px 15px 15px;

    img {
      max-width: 100%;
    }

    &.header {
      position: relative;
      .magnifying-glass-icon {
        position: absolute;
        bottom: -10px;
        right: 0;
        margin-right: 15px;
      }
    }
  }

  .magnifying-glass-icon {
    justify-content: flex-end;
    display: flex;
    margin-right: 15px;
    cursor: pointer;
  }

  .icon-pause {
    color: #0249fb;
    cursor: pointer;
    font-size: 16px;
  }

  .watch-box {
    border-left: 2px solid #ecf0f3;
    display: flex;
    align-items: center;
  }

  .stopwatch {
    span {
      font-family: 'Poppins';
      font-size: 14px;
      font-weight: 400;
      color: #0249fb;
    }
  }
}

.custom-success-footer {
  display: flex;
  gap: 12px;
}
