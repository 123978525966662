#widget-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.15);

  .performance-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 11px;
    background: white;
    height: 174px;
  }

  .title-performance {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .text-performace,
  .text-ranking {
    display: flex;
    flex-direction: column;
    margin-left: 18px;
  }

  .performance-data {
    display: flex;
    flex-direction: column;
  }

  .box-performance-data,
  .box-ranking-data {
    margin-top: 18px;
    margin-right: 45px;
    margin-bottom: 20px;
    margin-left: 45px;
  }

  .performance-graphic-icon,
  .ranking-icon {
    padding: 18px;
    background: #e7f6ff;
    border-radius: 11px;
  }

  .first-line-performance,
  .first-line-ranking {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
  }

  .second-line-performance,
  .second-line-ranking {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    color: #b5b5c3;
  }

  .performance-data,
  .ranking-data {
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-top: 14px;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 500;
    color: #b5b5c3;
  }

  .happy-student-image {
    margin-right: 48px;
    margin-top: -38px;
    scale: 0.87;
  }

  .date-panel {
    display: flex;
    gap: 10px;
    margin-left: 5px;
    //margin-top: 20px;

    .date-text {
      font-weight: 600;
    }

    .exam-text {
      color: #494949;
    }

    .images {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 4px;
      gap: 4px;
      //justify-content: center;

      img:nth-child(2) {
        height: 26px;
        //box-shadow: 1px 1px 1px #E5EAEE;
      }
    }
  }
}
