#confirm-modal {

}

.confirm-modal{
  button {
    width: 100px;
    height: 40px;
  }

  .modal-content {


    @media (width <= 768px) {
      max-width: 400px;
      top: 35vh;
      position: absolute;
    }
  }

  .custom-success-footer{
    display: flex;
    gap: 20px;
  }

  .modal-body {
    padding: 2rem;
    //width: 80vw;
    span {
      width: 300px;
      font-weight: 600;
      text-align: center;
    }
  }

  .cover-image {
    height: 500px;
    width: 100%;
  }
}