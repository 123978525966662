#help {
  padding: 10px 30px;
  max-width: 1100px;
  background: #fbf9f6;

  header {
    background-color: #0249fb;
    padding: 20px 30px;
    color: white;
    border-radius: 12px;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    height: 91px;
    align-items: center;
    justify-content: space-between;

    > img {
      width: 154px;
    }
  }

  .heading {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-left: 2px;
    h1 {
      font-style: normal;
      font-weight: 900;
      font-size: 26px;
      line-height: 28px;
      color: #4c4e64;
      margin-left: 4px;
    }

    h2 {
      font-style: normal;
      font-weight: 900;
      font-size: 23px;
      line-height: 28px;
      text-transform: uppercase;
      color: #0249fb;
      margin-left: 4px;
    }

    img {
      //max-width: 80vw;
      margin-top: 18px;
      border-radius: 12px;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    }
  }
}
.subscribe {
  width: 1144px;
  height: 88.52px;
  background: #0249fb;
  border-radius: 6.98168px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  margin: 24px 30px;

  > span:first-child {
    font-family: 'Poppins';
    font-weight: 800;
    font-size: 18px;

    color: rgba(255, 255, 255, 0.9);
  }

  > span:last-child {
    padding: 10.0814px 13.4419px;
    width: 213.82px;
    height: 49.16px;
    background: #f3f6f9;
    border-radius: 5.04071px;
    color: #0249fb;
    font-family: 'Poppins';
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }
}

.step5-img {
  display: flex;
  gap: 24px;
  img {
  width: 100%;
  }
}

.img1 {
  width: 40%;
}

.img2 {
  width: 58%;
}

.step-7 {
  display: flex;
  gap: 24px;
}

.step7-img {
  width: 50%;
}

.step7-img2 {
  width: 48%;
}
