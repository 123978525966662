#notfound {
  background-image: url('../../../public/media/images/image-ops.png');
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  height: 100vh;
  width: 100%;

  .logo-mock {
    width: 230px;
    margin-top: 14rem;

    @media (width <= 540px) {
      width: 200px;
    }
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
  }

  .first-line {
    font-family: 'Poppins';
    font-weight: 700;
    font-size: 150px;
    color: #0249fb;
    margin-top: 14rem;

    @media (width <= 540px) {
      font-size: 30vw;
    }
  }

  .second-line {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 18px;
    color: #a7a8bb;
    margin-bottom: 25px;
    @media (width <= 540px) {
      font-size: 4vw;
    }
  }

  .btn {
    width: 127px;
    height: 43px;
    background: #0249fb;
    border-radius: 8px;
    border: none;

    @media (width <= 540px) {
      width: 40vw;
    }
  }
  .text-btn {
    font-family: 'poppins';
    color: white;
    font-size: 12px;
    font-weight: 500;

    @media (width <= 540px) {
      font-size: 4vw;
    }
  }
}
