.flex-root {
  //height: 100vh;
  background: white;

  @media (height > 950px) {
  > div {
    height: 100vh;
  }
  }
}

.login-content {
  //background: white;
}

.login-container {
  width: 100%;
  display: flex;

  @media (width <= 1000px) {
    flex-direction: column;
  }


}

.bg-image {
  background: linear-gradient(180deg, #01A1FB 0%, #0147FB 100%);
  width: 46%;

  @media (width <= 1000px) {
    width: 100%;
  }

}

.login-elements{
  display: flex;
  flex-direction: column;
  gap:48px;
  margin-top:56px;

  @media (width <= 1000px) {
    gap:36px;
    align-items: center;
  }

}

.login-image{
  scale: 0.98;
  margin-bottom: 56px;
  margin-left:170px;
  max-width: 102%;

  @media (width <= 1440px) {
    
    margin-left: 6vw;
  }

  @media (width <= 1000px) {
    width: 55vw;
    margin-left: 22vw;
  }

  @media (width <= 520px) {
    width: 78vw;
    margin-left: 10vw;
  }

}

.logo-mock{
  //scale: 0.85;

  @media (width <= 1000px) {
    width: 40vw;
  }


}

.fw-light fs-2x text-white lh-lg{
  font-size: 18px;
}

.marketing-text{
font-size: 28px;
color: white;
text-align: initial;  
line-height: 1.4;

@media (width <= 1000px) {
  font-size: 3vw;
}

@media (width <= 520px) {
  font-size: 4.5vw;
}



}
  
