#exam-perform-type-3 {
  .answer-card {
    min-width: auto !important;
  }
  .type-3-answers-card {
    display: flex;
    flex-direction: column;
    //width: 350px;
    gap: 12px;
    //background-color: white;
    border-radius: 11px;
    height: 100vh;

    .subject-container {
      background: white;
      padding: 20px 5px 15px 15px;
    }

    .header-section {
      font-family: 'Poppins';
      font-size: 16px;
      font-weight: 700;
      padding: 32px;
      //border-bottom: 1px solid #ecf0f3;
    }

    .answers-options-description {
      display: flex;
      margin-left: 51px;
      //justify-content: flex-end;
      gap: 14px;
      font-family: 'Poppins';
      font-size: 12px;
      font-weight: 600;

      > span {
        width: 45.25px;
        height: 16px;
      }
    }

    .content-answers {
      padding: 19px;
    }

    .answers-container {
      display: flex;
      flex-wrap: wrap;
      gap: 14px;
      margin-top: 6px;
      width: 100%;


      .question-number {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 10px;
        text-align: center;
        height: 16px;
        color: black;
        margin-right: 10px;
        font-family: 'Poppins';
        font-size: 12px;
        font-weight: 600;
      }
    }

    .answers-options {
      display: flex;
      align-items: center;
      gap: 14px;
      width: 100px;

      img {
        /* width: 38px;
        height: 16px; */
        cursor: pointer;
      }
    }
  }
}
