

/* Flexbox */
.flex { display: flex; }
.flex-col { flex-direction: column; }
.flex-row { flex-direction: row; }
.items-start { align-items: flex-start; }
.items-center { align-items: center; }
.items-end { align-items: flex-end; }
.justify-start { justify-content: flex-start; }
.justify-center { justify-content: center; }
.justify-end { justify-content: flex-end; }
.space-x-4 > * + * { margin-left: 1rem; }
.space-y-4 > * + * { margin-top: 1rem; }

/* Margins */
.m-1 { margin: 0.25rem; }
.m-2 { margin: 0.5rem; }
.m-3 { margin: 0.75rem; }
.m-4 { margin: 1rem; }
.m-5 { margin: 1.25rem; }
.mx-2 { margin-left: 0.5rem; margin-right: 0.5rem; }
.my-2 { margin-top: 0.5rem; margin-bottom: 0.5rem; }
.mt-2 { margin-top: 0.5rem; }
.mb-2 { margin-bottom: 0.5rem; }
.ml-2 { margin-left: 0.5rem; }
.mr-2 { margin-right: 0.5rem; }

/* Gaps */
.gap-1 > * + * { gap: 0.25rem; }
.gap-2 > * + * { gap: 0.5rem; }
.gap-3 > * + * { gap: 0.75rem; }
.gap-4 > * + * { gap: 1rem; }
.gap-5 > * + * { gap: 1.25rem; }

/* Padding */
.p-1 { padding: 0.25rem; }
.p-2 { padding: 0.5rem; }
.p-3 { padding: 0.75rem; }
.p-4 { padding: 1rem; }
.p-5 { padding: 1.25rem; }
.px-2 { padding-left: 0.5rem; padding-right: 0.5rem; }
.py-2 { padding-top: 0.5rem; padding-bottom: 0.5rem; }
.pt-2 { padding-top: 0.5rem; }
.pb-2 { padding-bottom: 0.5rem; }
.pl-2 { padding-left: 0.5rem; }
.pr-2 { padding-right: 0.5rem; }
@import "_variables.scss";
@import "_fonts.css";

:root{
  --color-main: #42BABF;
  --color-darkermain: #31898C;
  --color-turquoise: #66C1BF;
  --color-aquamarine: #95CFBD;
  --color-passwordgreen: #3DCC80;
  --color-selectcontainer: #40D6B1;
  --color-deepblue: #40AAD6;
  --color-darkred: #BB1817;
  --color-orangebrown: #b36e6e;
  --color-lightred: #C70D6C ;
  --color-darkpurple: #9966C1;
  --color-purple: #3D7CCC;
  --color-lightorange: #FFD8C1;
  --color-darkorange: #FF9153;
  --color-blackoutline: #1D1D1D;
  --color-lightgrey: #6F7576;
  --color-lightergrey: #C4C4C4;
  --color-o-grey: #dbcece;
  --color-grey: #A0B0B0;
  --color-white: #FFFFFF;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  background-color: #fbf9f6;
}


hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #181C32;
}

h1, .h1 {
  font-size: calc(1.3rem + 0.6vw);
}


h2, .h2 {
  font-size: calc(1.275rem + 0.3vw);
}

h3, .h3 {
  font-size: calc(1.26rem + 0.12vw);
}


h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5 {
  font-size: 1.15rem;
}

h6, .h6 {
  font-size: 1.075rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

* {
  font-family: Poppins, Manrope, Helvetica, Inter, Roboto "sans-serif";
}
.main-content-container{
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;

  .main-content-wrapper {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    //margin-top: 3em;

    .main-content{
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1;
      min-height: 70vh;
      min-width: 0;

      .content-data {
        //margin-top: 3em;
      }

      @media (width < 1280px) {
        margin-left: $sidebarWidthTablet;
      }

      @media (width >= 1280px) {
        margin-left: $sidebarWidth;
      }

      @media (width <= 860px) {
        margin-left: $sidebarWidthSmall;
      }

      &:only-child {
        margin-left: 0;
      }
    }
  }
}

.toast-pos {
  padding-top: 12px;
  padding-right: 5px;
}

.toast-body{
  background: #F3F6F9 ;
  border-radius: calc(0.65rem - 1px);
}

.back-btn {
  padding: 0 !important;
  height: 44px;
  width: 121px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #0249FB;
    font-size: 32px;
    //position: absolute;
  }
  span {
    color: #0249FB;
    font-weight: 600;
  }
}

button.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
  padding: calc(0.75rem + 1px) calc(1rem + 1px);
}

.btn-short {
  //height: 20px;
  //padding: 5px 5px;
  height: 34px;
  line-height: 1;
  font-size: 13px;
}

.zoom-modal {
  .modal-content {
    width: 85vw;
  }

  img {
    max-width: 83vw;
    //max-height: 80vh;
    object-fit: contain;
  }
}

.d-flex {
  display: flex;
}

/* Custom Scrollbar */

/* width */
::-webkit-scrollbar {
  width: 0.5em;
  //display: none;
  @media screen and (max-width: 768px) {
    //display: none;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: #ECF5F4;
  //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:#0249FB;
  //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  border-radius: 15px;
}

/* Handle on hover */
//::-webkit-scrollbar-thumb:hover {
  //background: var(--color-main);
//}

.modal {
  //display: flex !important;
  //justify-content: center !important;
}

.modal-dialog {
  display: flex;
  //justify-content: center;
}

.modal-dialog-centered {
  justify-content: center;
}

.modal-header {
  //padding: 20px 25px;
  .modal-title {
    height: 15px;
  }
  .btn-close {
    width: 25px;
  }
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 0.75rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.ml-5 {
  margin-left: 1.25rem !important;
}

.global-modal {
  .modal-body {
    min-width: 470px;
    //min-height: 200px;

    @media (width < 500px) {
      min-width: 90vw;
    }
  }
}

.modal-header {
  display: flex;
  padding-left: 30px;
}

.btn-close {
  z-index: 9999;
}

.apexcharts-toolbar {
  display: none !important;
}

.CookieConsent {
  background: rgb(227, 227, 227) !important;

  div {
    color: #0249fb;
  }

  button {
    background: #0249fb !important;
    color: white !important;
    border-radius: 5px !important;
  }
}

/* Flexbox */
.flex { display: flex; }
.flex-col { flex-direction: column; }
.flex-row { flex-direction: row; }
.items-start { align-items: flex-start; }
.items-center { align-items: center; }
.items-between { align-items: space-between; }
.items-end { align-items: flex-end; }
.justify-start { justify-content: flex-start; }
.justify-center { justify-content: center; }
.justify-between { justify-content: space-between; }
.justify-end { justify-content: flex-end; }
.space-x-4 > * + * { margin-left: 1rem; }
.space-y-4 > * + * { margin-top: 1rem; }

/* Margins */
.m-1 { margin: 0.25rem; }
.m-2 { margin: 0.5rem; }
.m-3 { margin: 0.75rem; }
.m-4 { margin: 1rem; }
.m-5 { margin: 1.25rem; }
.mx-2 { margin-left: 0.5rem; margin-right: 0.5rem; }
.my-2 { margin-top: 0.5rem; margin-bottom: 0.5rem; }
.mt-2 { margin-top: 0.5rem; }
.mb-2 { margin-bottom: 0.5rem; }
.ml-2 { margin-left: 0.5rem; }
.mr-2 { margin-right: 0.5rem; }
.mx-3 { margin-left: 0.75rem; margin-right: 0.75rem; }
.my-3 { margin-top: 0.75rem; margin-bottom: 0.75rem; }
.mt-3 { margin-top: 0.75rem; }
.mb-3 { margin-bottom: 0.75rem; }
.ml-3 { margin-left: 0.75rem; }
.mr-3 { margin-right: 0.75rem; }
.mx-4 { margin-left: 1rem; margin-right: 1rem; }
.my-4 { margin-top: 1rem; margin-bottom: 1rem; }
.mt-4 { margin-top: 1rem; }
.mb-4 { margin-bottom: 1rem; }
.ml-4 { margin-left: 1rem; }
.mr-4 { margin-right: 1rem; }
.mx-5 { margin-left: 1.25rem; margin-right: 1.25rem; }
.my-5 { margin-top: 1.25rem; margin-bottom: 1.25rem; }
.mt-5 { margin-top: 1.25rem; }
.mb-5 { margin-bottom: 1.25rem; }
.ml-5 { margin-left: 1.25rem; }
.mr-5 { margin-right: 1.25rem; }
.mr-6 { margin-right: 1.5rem; }
.ml-6 { margin-left: 1.5rem; }
.mt-6 { margin-top: 1.5rem; }
.mb-6 { margin-bottom: 1.5rem; }
.mr-7 { margin-right: 2rem; }
.ml-7 { margin-left: 2rem; }
.mt-7 { margin-top: 2rem; }
.mb-7 { margin-bottom: 2rem; }
.mr-8 { margin-right: 2.5rem; }
.ml-8 { margin-left: 2.5rem; }
.mt-8 { margin-top: 2.5rem; }
.mb-8 { margin-bottom: 2.5rem; }
.mr-9 { margin-right: 3rem; }
.ml-9 { margin-left: 3rem; }
.mt-9 { margin-top: 3rem; }
.mb-9 { margin-bottom: 3rem; }
.mr-10 { margin-right: 3.5rem; }
.ml-10 { margin-left: 3.5rem; }
.mt-10 { margin-top: 3.5rem; }
.mb-10 { margin-bottom: 3.5rem; }
.mx-auto { margin-left: auto; margin-right: auto; }
.mt-auto { margin-top: auto; }
.mb-auto { margin-bottom: auto; }
.ml-auto { margin-left: auto; }
.mr-auto { margin-right: auto; }


/* Gaps */
.gap-1 > * + * { gap: 0.25rem; }
.gap-2 > * + * { gap: 0.5rem; }
.gap-3 > * + * { gap: 0.75rem; }
.gap-4 > * + * { gap: 1rem; }
.gap-5 > * + * { gap: 1.25rem; }

/* Padding */
.p-1 { padding: 0.25rem; }
.p-2 { padding: 0.5rem; }
.p-3 { padding: 0.75rem; }
.p-4 { padding: 1rem; }
.p-5 { padding: 1.25rem; }
.px-2 { padding-left: 0.5rem; padding-right: 0.5rem; }
.py-2 { padding-top: 0.5rem; padding-bottom: 0.5rem; }
.pt-2 { padding-top: 0.5rem; }
.pb-2 { padding-bottom: 0.5rem; }
.pl-2 { padding-left: 0.5rem; }
.pr-2 { padding-right: 0.5rem; }
/* Borders */
.border-c { border-width: 1px; border-style: solid !important; }
.border-2 { border-width: 2px; }
.border-4 { border-width: 4px; }
.border-t { border-top: 1px solid; }
.border-b { border-bottom: 1px solid; }
.border-l { border-left: 1px solid; }
.border-r { border-right: 1px solid; }
.border-gray { border-color: #ccc; }
.border-red { border-color: #e3342f; }
.border-green { border-color: #38c172; }
.border-blue { border-color: #3490dc; }
.border-yellow { border-color: #f6993f; }
.border-none { border-width: 0; }
/* Border Radius */
.rounded { border-radius: 0.25rem; }
.rounded-sm { border-radius: 0.125rem; }
.rounded-md { border-radius: 0.375rem; }
.rounded-lg { border-radius: 0.5rem; }
.rounded-xl { border-radius: 1rem; }
.rounded-full { border-radius: 9999px; }

.cursor-pointer { cursor: pointer; }

.opacity-0 { opacity: 0; }