#AnswersCardHeader {
  .exam-header {
    display: flex;
    flex-direction: column;
    font-family: arial;
    color: #3f4254;
    gap: 12px;
    margin-bottom: 32px;
    width: 100%;
  }

  .first-header-section {
    display: flex;
    width: 100%;
    gap: 8px;
  }

  .container-date {
    background: white;
  }

  .date,
  .date-text {
    border: solid 1px #3f4254;
    font-family: Arial;
    font-size: 9px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33.4%;
    border-bottom: none;
  }

  .date-text-header {
    display: flex;
    justify-content: center;

    span {
      padding: 2px 0;
    }
  }

  .date {
    font-size: 12px;
    font-weight: 500;
    width: 100%;
  }

  .boxs-date {
    display: flex;
  }

  .box-date {
    border: solid 1px #3f4254;
    padding: 1px 6.5px;
    font-weight: 600;
    font-size: 13px;
    font-family: cursive;
    display: flex;
  }

  .student-name {
    color: black;
    font-family: cursive;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    flex-grow: 1;
    line-height: 1;
  }

  .data-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .data-line {
    border: solid 1px #3f4254;
    //padding-bottom: 3px;
    font-size: 12px;
    font-weight: 500;
    height: 100%;
    display: flex;
    align-items: center;
    //width: 50%;
    padding-left: 4px;

    background: white;
  }

  .second-header-section {
    display: flex;
    width: 100%;
    gap: 8px;
  }

  .name-box,
  .instruction-box {
    height: 90px;
    border: solid 1px #3f4254;
    background-color: white;
    padding: 6px 6px;
    display: flex;
    flex-direction: column;
    font-size: 12px;

    @media (width < 1404px) {
      height: 120px;
    }

    @media (width < 1299px) {
      height: 132px;
    }
  }

  .name-box {
    gap: 42px;
    width: 32%;

    @media (width < 1404px) {
      gap: 62px;
    }

    @media (width < 1299px) {
      gap: 46px;
    }
  }

  .instruction-box {
    gap: 8px;
    width: 70%;
    font-size: 12px;

    @media (width < 1404px) {
      gap: 13px;
    }

    @media (width < 1299px) {
      gap: 13px;
    }
  }
}
