$fullWidth: 90vw;
//$offsetWidth: 1100px;
$animationWidth: 901px;
$animationWidthNeg: -890px;

$fullWidthMobile: 90vw;
$offsetWidthMobile: 80vw;
$animationWidthMobile: 89vw;
$animationWidthNegMobile: -89vw;

#exam-results-modal {
}

.exam-results-modal {
  .exam-date-container {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 10px;

    span {
      font-weight: 500;
      font-size: 15px;
    }

    .official {
      font-weight: bold;
    }

    .form-select {
      padding: 5px 10px;
      width: 250px;
      font-size: 13px;
    }
  }

  .client-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 452px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 19vh;

    @media (width < 900px) {
      width: 100%;
      padding: 0 10px;
      span {
        text-align: center;
      }
    }

    span {
      color: #494949;
      font-size: 14px;
      font-weight: 500;
    }

    .btns-panel {
      display: flex;
      flex-direction: column;
      margin-top: 7px;
      gap: 15px;
      width: 100%;

      button {
        width: 100%;
        height: 45px;

        &.cancel {
          background-color: #A7A8BB;
        }
      }
    }
  }
  .chart-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-weight: 600;
    font-size: 16.5px;
    gap: 0px;
    padding: 25px 30px;

    > div {
      width: 100%;
      //flex-grow: 1;
    }

    .left-panel {
      font-weight: bold;
      display: flex;
      //min-height: 100%;
      flex-direction: column;
      padding: 0px 12px;
      width: 190px;

      span {
        margin: 5px 0;
        color: #7e8299;
        cursor: pointer;
        //padding: 20px 30px;
        height: 53px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.selected {
          //color: red;
          padding: 0px 30px;
          background-color: #f3f6f9;
          border-radius: 15px;
        }
      }
    }

    .right-panel {
      //width: 100%;
      //min-width: 100%;
    }
  }

  button {
    width: 100px;
    height: 40px;
  }
  .modal-header {
    height: 60px;
    display: flex;
    background-color: #EDEDED;
    justify-content: center;

    .title-container {
      display: flex;
      gap: 30px;
      span {
        width: 167px;
        font-size: 17px;
        font-weight: 600;
        cursor: pointer;
        text-align: center;
        height: 40px;
        //padding-bottom: 20px;
        margin-top: 18px;

        &.selected {
          border-bottom: 5px solid #0249FB;
        }

        @media (width < 900px) {
          width: 100px;
          font-size: 14px;
        }
      }

      @media (width < 900px) {
        gap: 12px;
      }
    }
  }

  .modal-content {
    width: $fullWidth;

    @media (width < 900px) {
      width: $fullWidthMobile;
    }
  }

  .modal-header {
    display: flex;
    padding-left: 15px;

    @media (width < 900px) {
      padding-left: 6px;
    }
  }

  .modal-body {
    //padding: 2rem;
    //min-width: $offsetWidth;
    overflow: auto;
    min-height: 400px;

    @media (width < 900px) {
      //min-width: $offsetWidthMobile;
    }
  }

  .table {
    width: 100%;
  }

  tr {
    border-bottom: 1px solid #e5eaee;
  }

  thead {
    tr {
      th {
        font-weight: bold;
      }
    }
  }

  tbody {
    tr {
      td {
        &.question-number {
          font-weight: bold;
          padding-left: 3px;
        }

        &.question-img-box, .resolution-td  {
          display: flex;
          justify-content: flex-start;
          gap: 15px;
          .q-image {
            //height: 50px;
            height: 37px;
            max-width: 90px;
            min-width: 90px;
            object-fit: contain;
          }
        }

        &.header-resolution {
          display: flex;
          flex-direction: column;
          gap: 5px;
          align-items: center;
          .q-image {
            max-width: 60px;
            min-width: 60px;
          }
        }

        .question-img {
          display: flex;
          justify-content: flex-start;
          gap: 15px;
        }

      }
    }
  }

  .subject-container-parent {
    .header-box {
      margin-bottom: 6px;
      margin-left: -2px;
      span {
        font-size: 20px;
        //font-weight: 600;
      }
    }
  }

  .answer-box {
    display: flex;
    gap: 6px;
    img {
      margin-right: 6px;
    }

    &.is-math {
      flex-direction: row;
      gap: 0;
      .option-row {
        //margin-left: 5px !important;
      }
    }
  }
  .option-row {
    display: flex;
    gap: 8px;
  }

  .magnifying-glass-icon {
    width: 15px;
    object-fit: contain;
    cursor: pointer;
    //height: 10px;
  }

  .video-td {
    width: 40px;
    display: flex;
    justify-content: center;

    img {
      cursor: pointer;
    }
  }

  td, th {
    max-width: 120px;
    vertical-align: middle;
  }

  .answers-container {
    padding: 2px 25px;
  }
}

.modal .exam-results-modal.modal-dialog {
  max-width: $fullWidth;
  height: 95vh;
}

/*
.modal .exam-results-modal.modal-dialog {
  position: fixed;
  margin: auto;
  min-width: $animationWidth;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);

  @media (width < 900px) {
    min-width: $animationWidthMobile;
  }
}

.modal .exam-results-modal .modal-content {
  height: 100%;
  margin-top: -1px;
  border-radius: 0;
  //  overflow-y: auto;
}

.modal.fade .exam-results-modal.modal-dialog {
  right: $animationWidthNeg;
  -webkit-transition: opacity 0.5s linear, right 0.5s ease-out;
  -moz-transition: opacity 0.5s linear, right 0.5s ease-out;
  -o-transition: opacity 0.5s linear, right 0.5s ease-out;
  transition: opacity 0.5s linear, right 0.5s ease-out;

  @media (width < 900px) {
    right: $animationWidthNegMobile;
  }
}

.exam-results-modal.anim-done.modal-dialog {
  right: -1px !important;
} */

.custom-success-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  //  margin-bottom: 50px;

  img {
    height: 75px;
    width: 77px;
  }

  span {
    margin: 15px 60px;
    //color: #C4C4C4;
    //font-style: italic;
    font-weight: bold;
  }
}

.custom-success-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 13px 0;
  button {
    font-weight: bold;
  }

  button {
    background: #40aad6;
    //font-style: italic;
    border-radius: 6px;
    border: none;
    font-size: 0.95em;
    //margin-right: 18px;
    padding: 5px 20px;
  }
}
