#admin-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 32px;
  gap: 23px;

  .widgets-container {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;

    #bar-chart {
      flex-grow: 1;

      .chart-container > div {
        width: 100%;
        max-width: 850px;
        //padding: 20px 10px;
      }
    }
  }

  .home-slider-wrapper{
    position: relative;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 2em auto;
  }

  .sign-out {
    cursor: pointer;
  }

  #widget-box {
    height: 243px;
    background-color: white;
  }

  #widget-box, .welcome-box {
    //width: 370px;
    height: 232px;
    flex-grow: 1;

    @media (width >= 500px) {
      width: calc(50% - 20px);
    }
  }

  .overtime-chart {
    display: flex;
    flex-direction: column;
    background: white;
    align-self: flex-start;
    border-radius: 10px;

    .left-container {
      display: flex;
      //align-items: center;
      height: 100%;
      .left-panel {
        font-weight: bold;
        display: flex;
        //min-height: 100%;
        flex-direction: column;
        justify-content: center;
        padding: 0px 12px;
        width: 190px;

        span {
          margin: 5px 0;
          color: #7E8299;
          cursor: pointer;
          //padding: 20px 30px;
          height: 53px;
          display: flex;
          justify-content: center;
          align-items: center;

          &.selected {
            //color: red;
            padding: 0px 30px;
            background-color: #F3F6F9;
            border-radius: 15px;
          }
        }
      }
    }

    .labels-panel {
      display: flex;
      gap: 41px;
      position: absolute;
      padding-left: 42px;
      margin-top: -60px;
      z-index: 9999;

      span {
        color: white;
        width: 30px;
        text-align: center;

        &.zero-value {
          //background-color: #F3F6F9;
          color: #000;
          margin-top: 10px;
        }
      }
    }
  }

  .overtime-header {
    display: flex;
    justify-content: space-between;
    //gap: 30px/;
    padding: 10px 60px 10px 30px;

    .progress-title {
      font-weight: bold;
      font-size: 15px;
      margin-top: 12px;
    }

    .score-box {
      display: flex;
      gap: 30px;
    }

    .header-item {
      display: flex;
      flex-direction: column;
      //gap: 30px;

      span:nth-child(1) {
        color: #B5B5C3;
        font-weight: 600;
      }

      span:nth-child(2) {
        font-weight: bold;
        font-size: 15px;
      }
    }
  }

  .welcome-box {
    background-color: #0249FB;
    padding: 20px 25px;
    border-radius: 8px;

    span {
      color: white;
    }

    .title-welcome {
      display: flex;
      gap: 15px;

      div:nth-child(2) {
        display: flex;
        flex-direction: column;
      }

      .first-line {
        font-weight: bold;
        font-size: 16px;
      }
      .second-line {
        font-size: 15px;
        font-weight: 600;
      }
    }
    .welcome-data {
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-size: 16px;
      margin-top: 26px;
      font-weight: 500;
      margin-left: 3px;
    }
  }

  .try-btn {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    margin-top: 37px;
    span {
      color: #0249FB;
      font-size: 15px;
      //line-height: 1.2;
      font-weight: 600;
      margin-right: 8px;
    }
  }

  #donut-chart {
    @media (width >= 1400px) {
      width: 400px;
    }

    @media (width >= 1600px) {
      width: 500px;
    }
    //width: 400px;
  }
}