#paymentplans {
  .plan-box-1-button {
    width: 213px;
    height: 42px;
    background: #0249fb;
    border-radius: 8px;
    border: none;
    box-shadow: 0px 4.2px 8.4px -4.2px rgba(76, 78, 100, 0.42);
    border-radius: 23px;
  }

  .plan-box {
    display: flex;
    margin-bottom: 7rem;

    gap: 29px;

    @media (width <= 1290px) {
      gap: 3rem;
      scale: 0.9;
    }

    @media (width <= 1170px) {
      scale: 0.82;
      margin-bottom: 3rem;
    }
  }

  .plan-box-2 {
    width: 298px;
    height: 567px;
    //margin-top: -4rem;
    background: #0249fb;

    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: baseline;
    padding: 40px 30px;

    box-shadow: 0px 41.9702px 33.9759px rgba(82, 67, 194, 0.295755);
    border-radius: 25.9816px;
  }

  .plan-box-text {
    display: flex;
    gap: 10px;
    //justify-content: center;
    align-items: center;
  }

  .plan-box-1-text {
    display: flex;
    flex-direction: column;
    gap: 9px;
    margin-top: 20px;
  }

  .first-section-plan-box-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }

  .plan-box-2-button,
  .plan-box-2-second-button {
    width: 233.36px;
    height: 44.1px;
    margin-top: -10px;
    width: 213px;
    height: 46px;
    background: #ffffff;

    border: none;
    box-shadow: 0px 4.2px 8.4px -4.2px rgba(76, 78, 100, 0.42);
    border-radius: 15px;
    align-self: center;
  }

  .plan-box-2-second-button {
    margin-top: 1.8rem;
    border-radius: 27px;
  }

  .plan-box-2-line-1,
  .plan-box-2-line-2,
  .plan-box-2-line-4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 52.8481px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;

    gap: 8px;
    align-items: center;

    .faded {
      text-decoration: line-through;
      //color: rgba(76,78,100,.47);
      font-size: 23px;
      opacity: .7;
    }
  }

  .plan-box-2-line-2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 35px;
  }

  .plan-box-2-line-4 {
    font-weight: 400;
    font-size: 16px;
    text-align: initial;
  }

  .first-text-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;

    text-align: center;
    letter-spacing: 0.958904px;

    color: #0249fb;
  }
}
