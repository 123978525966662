.parental-password {
  .pwd-reset {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .modal-body {

    padding: 25px 30px;
  }
}