#success-modal {
}

.success-modal {
  button {
    width: 100px;
    height: 40px;
  }
  .modal-header {
    display: flex;
    justify-content: center;

    .modal-title {
      width: auto;
    }
  }

  .modal-content,
  .modal-body {
    width: 520px;
  }

  .modal-body {
    padding: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    span {
      //width: 300px;
      font-weight: 600;
    }
  }

  .success-footer {
    width: 100%;
    margin-top: 30px;
    button {
      width: 100%;
      font-size: 12px;
      font-weight: 400;
    }
  }

  .container-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 28px;
  }

  .first-line-modal {
    font-family: poppins;
    color: #20d489;
    font-weight: 600;
    font-size: 18px;
    text-align: center;

    &.black {
      color: black;
    }
  }

  .second-line-modal {
    font-family: poppins;
    color: #3f4254;
    font-size: 16;
    font-weight: 600;
  }

  .text-description,
  .text-description-2,
  .text-description-3 {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
    margin-bottom: 8px;
    padding-bottom: 4px;
    border-bottom: solid 1px #ababab;

    width: 80%;
    align-items: center;
  }

  .text-description-3 {
    border: none;
    margin-top: 8px;
    margin-bottom: 32px;
  }

  .text-description-2 {
    margin-top: 8px;
  }
}
