#free-test {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  background-color: white;

  //background: url('../../../public/media/images/background.png');

  .main-content {
    background-color: white;
  }

  .first-section {
    display: flex;
    //margin-top: 12vw;
    padding-top: clamp(16px, 10vw, 150px);
    justify-content: center;
    align-items: center;

    @media (width <= 1024px) {
      display: flex;
      flex-direction: column-reverse;
      gap: 4rem;
      align-items: center;
    }
  }

  .first-section-container {
    max-width: 1228px;
    display: flex;
    align-items: center;
    gap: 11rem;

    @media (width <= 1290px) {
      max-width: 1100px;
      gap: 9rem;

    }

    @media (width <= 1170px) {
      max-width: 980px;
      gap: 6rem;

    }

    @media (width <= 1024px) {
      display: flex;
      flex-direction: column-reverse;
      gap: 4rem;
      align-items: center;
      margin-left: 6%;
      margin-right: 6%;
    }
  }

  .title-first-section {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    line-height: 1.3;
    /* or 126% */

    display: flex;
    align-items: center;

    background: linear-gradient(94.76deg, rgba(2, 73, 251, 0.9) 9.51%, rgba(1, 42, 146, 0.9) 91.12%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @media (width <= 1290px) {
      font-size: 47px;
    }

    @media (width <= 1170px) {
      font-size: 43px;
    }

    @media (width <= 1024px) {
      font-size: 6.5vw;
    }

    @media (width <= 680px) {
      font-size: 7vw;
    }

    //@media (width <= 420px) {
    //font-size: 10vw;

    //}

    //@media (width <= 700px) {
    //font-size: 7vw;

    //}
  }

  .left-panel-first-section {
    display: flex;
    flex-direction: column;
  }

  .left-panel-boxes {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    width: 490px;

    @media (width <= 1170px) {
      width: 463px;
    }
    @media (width <= 1170px) {
      width: 472px;
    }

    @media (width <= 1024px) {
      width: 570px;
    }

    @media (width <= 800px) {
      width: 490px;
    }

    @media (width <= 680px) {
      width: 76vw;
    }
  }

  .line-1,
  .line-2,
  .line-3,
  .line-4 {
    display: flex;
    gap: 1rem;
    border: 1.5px solid #0249fb;

    padding: 10px 1rem;
    border-radius: 9px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height, or 131% */

    display: flex;
    align-items: center;

    color: #3f4254;

    @media (width <= 1400px) {
      font-size: 15px;
      //padding: 7px 1rem;
    }

    @media (width <= 1170px) {
      font-size: 16px;
      padding: 7px 1rem;
    }

    @media (width <= 1024px) {
      font-size: 16px;
      padding: 14px 1rem;
    }
  }

  .image-first-section {
    margin-top: 3rem;

    @media (width <= 1290px) {
      width: 40vw;
    }

    @media (width <= 1080px) {
      width: 42vw;
    }

    @media (width <= 1024px) {
      width: 68vw;
    }

    @media (width <= 800px) {
      width: 75vw;
    }
  }

  .free-test-container-header {
    display: flex;
    justify-content: flex-start;
    gap: 5rem;
    background-color: white;
    //box-shadow: 0px 0px 27px rgb(0 0 0 / 15%);
    //margin-left: 6%;

    overflow-x: auto;
    padding-left: 36px;
    padding-right: 36px;
    border-bottom: 2px solid #ebebeb;

  }

  @media (width < 750px) {
  ::-webkit-scrollbar {
//    width: 0.5em !important;
    height: 0.5em !important;
    display: none !important;
  }
  }

  .exam-icons {
    height: 36px;
  }

  .table-content {
    margin-top: 3px;
    padding-top: 0;
  }

  .type-exams {
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 12px;
    width: 220px;
    cursor: pointer;
    padding-bottom: 5px;

    &.selected {
      border-bottom: 2px solid #0249fb;
    }
  }

  .exam-name-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height, or 75% */

    @media (width <= 1024px) {
      font-size: 16px;
    }

    color: #181c32;
  }

  .second-section-test {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 7rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 4rem;

    text-align: center;

    background: linear-gradient(94.76deg, rgba(2, 73, 251, 0.9) 9.51%, rgba(1, 42, 146, 0.9) 91.12%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @media (width < 450px) {
      font-size: 36px;
    }

    @media (width <=1024px) {
      font-size: 7vw;
      margin-left: 15%;
      margin-right: 15%;
      line-height: 70px;
    }

    @media (width <=580px) {
      
      line-height: 50px;
      margin-top: 4rem;
    }
  }

  .free-test-container-body {
    height: 635px;
    overflow-y: auto;
  }

  .third-section,
  .fifth-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 6%;
    margin-right: 6%;
    //margin-top: 6rem;

    @media (width >=1450px) {
      margin-left: 10% ;
      margin-right: 10%;
    }

    @media (width >=1650px) {
      margin-left: 14% ;
      margin-right: 14%;
    }

    @media (width >=1750px) {
      margin-left: 17% ;
      margin-right: 17%;
    }
    

    @media (width <=1024px) {
      margin-left: 8%;
      margin-right: 8%;
    }

    @media (width <=750px) {
      margin-left: 5%;
      margin-right: 5%;
    }
  }

  .box-shadow {
    box-shadow: 0px 0px 27px rgba(0, 0, 0, 0.15);
  }

  .fourth-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 2%;
    padding-right: 2%;
    margin-top: 4rem;
    height: 88px;
    margin: 2rem 2.5rem;
    background: #0249fb;
    border-radius: 11px;
    //margin-left: 15%;
    //margin-right: 15%;

    @media (width < 750px) {
      flex-direction: column;
      height: 98px;
      padding-bottom:4%;
      margin: 1rem 1.5rem;
    }

    @media (width < 540px) {
      justify-content: center;
      padding-bottom:0%;
      gap: 8px;
      height: 86px;
    
    }


  }

  .fourth-section-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 50px;

    display: flex;
    align-items: center;

    color: rgba(255, 255, 255, 0.9);

    @media (width <= 980px) {
      font-size: 18px;
    }

    @media (width <= 580px) {
      font-size: 3vw;
      line-height: normal;
    }
  }

  .fourth-section-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 10px;
    width: 203px;
    height: 49px;
    background: #f3f6f9;
    border-radius: 6px;
    border: none;

    @media (width <= 980px) {
      width: 160px;
    }

    @media (width <= 750px) {
      width: 33vw;
      height: 34px;
    }
  }

  .fourth-section-button-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 34px;
    color: #0249fb;

    @media (width <= 980px) {
      font-size: 16px;
    }
    @media (width <= 580px) {
      font-size: 3vw;
    }
  }
  .select-box {
    width: 34vw;
  }

  .skeleton-loader {
    padding: 2px 27px;

    >span {
      display: flex;
      flex-direction: column;
    }
  }
}
