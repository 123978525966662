#login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56%;

  .create-acc-warning {
    color: red;
    font-size: 14px;
    //text-decoration: underline;
  }

  @media (width <= 1000px) {
    width: 100%;
  }

  .login-title {
    font-size: 16px;
  }
  .login-instead {
    color: #0249FB;
    font-size: 16px;
    cursor: pointer;
    //margin-top: -1px;
  }

  #kt_login_signup_form {
    display: flex;
    max-height: 100vh;
    flex-direction: column;
    overflow-y: auto;
    align-items: center;
    /* justify-content: center; */
    padding-top: 20px;

    div {
      width: 420px;

      @media (width < 480px) {
        width: 87.5%;
      }
    }
  }

  .pupil {
    display: flex;
    gap: 2rem;
  }

  .size {
    font-size: 30px;
  }
  
}
