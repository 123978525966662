#pdf-modal {

}

.pdf-modal {
  .modal-content {
    width: 93vw !important;
    margin-left: 2vw;
  }
  .modal-body {
    width: 90vw;
    height: 80vh;
    overflow-y: auto;
    padding: 20px 30px !important;
  }
}

/* .confirm-modal{
  button {
    width: 100px;
    height: 40px;
  }

  .custom-success-footer{
    display: flex;
    justify-content: center;
  }


  .modal-body {
    padding: 2rem;
    //width: 80vw;
    span {
      width: 300px;
      font-weight: 600;
      text-align: center;
    }
  }

  .cover-image {
    height: 500px;
    width: 100%;
  }
} */