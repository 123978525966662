#exam-perform-tiffin {
  .answer-card {
    min-width: auto !important;
  }

  .tiffin-answers-card {
    display: flex;
    flex-direction: column;
    gap: 12px;
    //width: 350px;
    //background-color: white;
    border-radius: 11px;
    height: 100vh;

    .header-section {
      font-family: 'Poppins';
      font-size: 16px;
      font-weight: 700;
      padding: 5px 16px;
      //border-bottom: 1px solid #ecf0f3;
    }

    .subject-container {
      background: white;
      padding: 20px 0;
    }

    .answers-options-description {
      display: flex;
      margin-left: 64px;
      //justify-content: flex-end;
      gap: 14px;
      font-family: 'Poppins';
      font-size: 12px;
      font-weight: 600;

      > span {
        width: 44.5px;
        height: 16px;
      }
    }

    .content-answers {
      padding: 0 10px;
    }

    .answers-container {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      //width: 100%;
      padding-top: 8px;
      padding-bottom: 8px;

      .question-number {
        display: flex;
        align-items: center;
        justify-content: center;
        //width: 10px;
        text-align: center;
        height: 16px;
        color: black;
        //margin-right: 10px;
        background-color: rgb(214 235 255);
        padding: 12px 12px;
        border-bottom: 2px solid #5e7fc8;
        font-family: 'Poppins';
        font-size: 12px;
        font-weight: 600;
      }
    }

    .answers-options {
      display: flex;
      flex-direction: column;
      border: 1px solid #5e7fc8;
      gap: 10px;
      margin-left: 5px;

      .inner-option {
        display: flex;
        cursor: pointer;
        color: #f1416c;
        justify-content: center;
        //font-weight: 600;
      }
    }

    .selected {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 80%;
        display: block;
        height: 2px;
        background-color: black;
        top: 8.2px;
      }
    }
  }

  .math-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
    max-height: 250px;
    flex-wrap: wrap;
  }

  .red-box-image {
    width: 6px;
    height: 10px;
    //margin-left: -5px;
  }
}
