#exam-perform-gla {
  min-width: 45%;
  .gla-answers-card {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100vh;
    //background: white;
    border-radius: 11px;

    .subject-container {
      background: white;
      padding: 0 5px 15px 15px;
    }


    .answer-container {
      border: 2px solid #D71920;
      display: flex;
      flex-grow: 1;
      justify-content: space-between;

      .left-panel {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        //width: 45px;
        min-width: 35px;
        width: 100%;
        padding-bottom: 6px;
        font-weight: 500;
        font-size: 13.5px;
        margin-left: auto;

        .opts {
          display: flex;
          flex-direction: column;
          align-items: end;
          margin-top: 25px;
          width: 100%;
          margin-right: 4px;

          span {
            height: 17px;
            text-align: center;
            min-width: 10px;
            padding-left: 2px;
          }
        }

        .number {
          position: absolute;
          padding: 2px 0px;
          width: 24px;
          background: #D71920;
          //text-align: center;
          display: flex;
          justify-content: center;
          padding-right: 2px;
          color: white;
        }
      }

      .right-panel {
        display: flex;
        flex-direction: column;
        //align-items: flex-end;
        justify-content: flex-end;
        flex-wrap: wrap;
        background-color: #fbd7d8;
        padding-bottom: 6px;
        max-height: 143px;
        color: #f1416c;

        > div {
          //width: 46px;
          padding: 2px 6px;
          height: 17px;
          display: flex;
          gap: 6px;
          align-items: center;
          justify-content: center;
          //background-color: #fdebef;
          font-family: poppins;
          font-weight: 700;
          font-size: 12px;
        }
      }
    }

    .outer-option {
      //border: 1x solid red;
    }

    .inner-option {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 3px;
    }

    .header-section {
      display: flex;
      padding: 16px;
      font-family: 'Poppins';
      font-size: 16px;
      font-weight: 400;
      //border-bottom: 1px solid #ecf0f3;
    }

    .answer-card-gla {
      display: flex;
      gap: 12px;
      padding-left: 14px;
      padding-right: 10px;
      flex-wrap: wrap;
    }
  }

  .selected {
    text-decoration-line: line-through;
    text-decoration-thickness: 3px;
    text-decoration-style: solid;
    text-decoration-color: black;
  }

  .header{
    display: flex;
    flex-direction: column;
    font-family: arial;
    color: #F1416C;
    gap: 12px;
    margin-bottom: 32px
    
  }

  .line-1-header, .line-2-header{
    border: solid 1px #F1416C;
    //padding-bottom: 3px;
    font-size: 11px;
    font-weight: 500;
    height: 29px;
    display: flex;
    gap: 12px;
    align-items: center;
    //width: 50%;
    padding-left: 4px;
    
    
   
    background: white;}

    .line-2-header{
border-top: none;

    }


  .line-3-header{
    border: solid 1px #F1416C;
    //padding:2px 6px 10px;
    //height: 10px;
    font-size: 11px;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    padding: 7px;
    justify-content: center;
    display: flex;


    //flex-grow: 1;

  }

  .lines-header{
    flex-grow: 1;
    
  }

   .header-first-session{
    gap: 6px;


   }


.header-first-session{
  display: flex;
  justify-content: space-between;
}

.date, .date-text{
  border: solid 1px #F1416C;
  font-family: Arial;
  font-size: 8px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.3%;
  border-bottom: none;
}
.container-date{
  background: white;
}

.date{
  font-size: 12px;
  font-weight: 500;
  width: 100%;
}

.date-text-header{

  display: flex;
  justify-content: center;
  


}

.box-date{
  border: solid 1px #F1416C;
  padding: 1px 6.5px;
  display: flex;
}

.boxs-date{
  display: flex;
}

.second-section-header{
display: flex;
gap: 6px;


}

.number-pupil{
  border: solid 1px #F1416C;
  padding: 1px 0;
  flex-grow: 1;
  text-align: center;
}

.uniqui-numbers-pupil{
display: flex;
flex-direction: column;
flex-grow: 1;
background: white;
//align-items: flex-start;


}

.numbers-pupil{
display: flex;
height: 25px;
}

.student-name{
color: black;
font-family: cursive;
font-size: 13px;
font-weight: 600;
}


}
