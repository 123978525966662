#footer {
  .footer {
    display: flex;
    flex-direction: column;
    width: 100%;

    align-items: center;
    background: #002581;
    padding: 0px 8%;
    margin-top: 5rem;

    @media (width <= 1024px) {
      margin-top: 4rem;
      align-items: baseline;
    }
  }
  .eleven {
    margin-bottom: 6px;
  }
  .media-icons {
    display: flex;
    gap: 1.5rem;

    img {
      cursor: pointer;
    }
  }

  .footer-container {
    margin-top: 4rem;
    display: flex;
    gap: 112px;
    max-width: 1228px;

    @media (width <= 1340px) {
      gap: 80px;
    }

    justify-content: space-around;

    @media (width <= 1024px) {
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }
  }

  .first-session-footer,
  .second-session-footer,
  .third-session-footer,
  .fourth-session-footer,
  .buttons-fourth-section-footer {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 3rem;
  }

  .second-session-footer,
  .third-session-footer,
  .fourth-session-footer {
    margin-top: 2rem;
    gap: 1rem;
  }

  .buttons-fourth-section-footer {
    gap: 1rem;
    margin-top: 4rem;
  }

  .first-session-text,
  .text-second-session-footer,
  .fourth-session-footer,
  .website-information {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;

    display: flex;
    flex-direction: column;

    color: #ffffff;

    
  }

  .first-session-text{
    text-align: center;
  }

  .website-information {
    font-size: 14px;
    font-weight: 200;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 6rem;
    margin-bottom: 1.5rem;
  }
  .text-fourth-session-footer {
    font-size: 20px;
  }

  .title-second-session-footer {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.1;

    color: #ffffff;
  }

  .ios-button,
  .android-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 15px;
    gap: 10px;
    border: none;
    width: 186px;
    height: 41px;
    left: 1038px;
    top: 4994px;
    background: #f3f6f9;
    border-radius: 6px;
  }

  .android-button {
    width: 226px;
  }

  .ios-button-text,
  .android-button-text {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 15px;
    color: #0249fb;
  }

  .img-logo-footer {
    @media (width <= 1270px) {
      width: 95%;
    }

    @media (width <= 1024px) {
      width: 280px;
    }
  }

  span {
    cursor: pointer;
  }
}
