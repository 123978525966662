@import '../../_variables.scss';
.sidebar {
  /* @media (width < 923px) {
    display: none !important;
  } */

  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  //  background-color: var(--color-darkermain);
  background-color: #0249fb;
  display: flex;
  align-items: flex-start;
  padding-left: 24px;
  width: $sidebarWidth;
  height: 100%;
  min-height: 100vh;
  position: fixed;

  /* &.tablet {
    width: $sidebarWidthTablet;
  } */

 /*  @media (923px <= width <= 1200px) {
    width: $sidebarWidthTablet;
  } */

  @media (width < 1280px) {
    width: $sidebarWidthTablet;
  }

  @media (width > 1280px) {
    width: $sidebarWidth;
  }

  /* @media (width < 923px) {
    width: 0;
  } */

  @media (width <= 860px) {
    width: $sidebarWidthSmall;
    padding-left: 8px;
  }


  & > * {
    list-style: none !important;
    text-decoration: none !important;
  }

  .mock-logo {
    width: 205px;
    object-fit: contain;
    margin-bottom: 28px;
  }

  .mock-logo-short {
    height: 60px;
    object-fit: contain;
    margin-left: 6px;
    margin-bottom: 20px;
  }

  .nav > * {
    list-style: none !important;
    text-decoration: none !important;
  }

  .underline {
    cursor: pointer;
    opacity: 1;
    span {
      color: #83c8ca;
    }
  }

  .active {
    opacity: 1;
    img {
      opacity: 1;
    }
    span {
      color: white;
    }

    .icon-container {
      background-color: #003acc;
    }
  }

  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    border-radius: 6px;
  }

  .icons {
    display: flex;
    justify-content: flex-start;
    scale:1.1;
  }

  .icon-logout {
    width: 19px;
    height: 14px;
  }

  .nav-item {
    display: flex;
    align-items: center;
    gap: 20px;
    //width: 11rem;
    font-size: 15px;
    border-radius: 9px;

    span {
      color: white;
      font-family: 'Poppins';
      font-weight: 500;
      font-size: 15px;
    }

    .nav-link {
      padding: 0.5rem 0.5rem;
      color: white !important;
    }
  }

  .admin-options {
    width: 220px;
    margin: 30px 0px 0px 13px;
    padding-top: 20px;

    @media (width < 1280px) {
      width: 45px;
    }
   

    &.add-border {
      //border-top: 1px solid #17357E;
      border-top: 1px solid white;
    }

    .underline {
      margin-left: -13px;
    }
  }

  .admin-dash {
    margin-top: 0;
  }
}
