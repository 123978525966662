#standard-deviation {
  display: flex;
  flex-direction: column;

  span {
    font-weight: normal;
  }

  .standardized-chart {
    height: 500px;
    //animation: fadeIn 0.65s;
    opacity: 0;

    @media (width < 900px) {
      width: 700px;

      > div > div > div {
        left: -90px !important;
      }
    }
  }

  .your-pos-div {
    display: flex;
    gap: 12px;
    margin-left: 150px;
    margin-top: -45px;
    z-index: 9999;
  }

  .graphic-legend-container {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-top: 8px;
    min-width: 600px;

    .graphic-legend{
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  .color-legend-below-average,
  .color-legend-average,
  .color-legend-above-average,
  .color-legend-your-position {
    height: 20px;
    width: 20px;
    border-radius: 12px;
    font-weight: 600;
  }

  .text-legend {
    font-weight: 600;
    font-size: 15px;
  }

  .hidden {
    opacity: 0;
  }

  .show {
    animation: fadeIn 0.65s;
    opacity: 1;
  }
}
