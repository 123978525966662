#login {
  //margin-top: 12rem;
/*   .login-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
  } */

  .info-login {
    color: #5E6278;
    font-weight: 500;
    font-size: 12px;
    margin-top: 4px;
  }

  @media (width <= 1024px) {
    margin-top: 2rem;
  }
}

.reset-modal {
  .modal-body {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 17px;

    button {
      height: 45px;
      width: 120px;
    }
  }
}
