#calendar-widget {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;

  .performance-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 11px;
    background: white;
    //height: 174px;
    padding-left: 30px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 50px;

    @media (width < 1080px) {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }

    @media (width < 500px) {
      justify-content: center;
    }
  }

  .title-performance {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .text-performace,
  .text-ranking {
    display: flex;
    flex-direction: column;
    margin-left: 18px;
  }

  .performance-data {
    display: flex;
    flex-direction: column;
  }

  .box-performance-data,
  .box-ranking-data {
    /* margin-top: 18px;
    margin-right: 45px;
    margin-bottom: 20px;
    margin-left: 45px; */
  }

  .performance-graphic-icon,
  .ranking-icon {
    padding: 18px;
    background: #e7f6ff;
    border-radius: 11px;
  }

  .first-line-performance,
  .first-line-ranking {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
  }

  .second-line-performance,
  .second-line-ranking {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    color: #b5b5c3;
  }

  .performance-data,
  .ranking-data {
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-top: 14px;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 500;
    color: #b5b5c3;
  }

  .happy-student-image {
    margin-right: 48px;
    margin-top: -38px;
    scale: 0.87;
  }

  .date-panel {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 5px;
    //margin-top: 20px;

    .date-text {
      font-weight: 600;
    }

    .exam-text {
      color: #494949;
    }

    .images {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 4px;
      gap: 4px;
      //justify-content: center;

      img:nth-child(2) {
        height: 26px;
        //box-shadow: 1px 1px 1px #E5EAEE;
      }
    }
  }

  .content-div {
    display: flex;
    flex-direction: column;

    a {
      color: #0249fb;
      cursor: pointer;
    }
  }

  .reports {
    width: 100%;
    table {
      width: 100%;
    }
  }

  .reports-title {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .reports-list {
    //width: 400px;
    margin-top: 12px;
    font-size: 12px;
    font-weight: 400;
    color: #8f8f8f;
    height: 400px;
    overflow: auto;

    @media (width < 940px) {
      width: 55vw;
    }
  }

  .report-item {
    margin-top: 4px;
    > td {
      border: 1px solid #f2f2f2;
      padding: 8px 12px 8px 6px;
      //width: 200px;
    }
  }
  .tr {
    > th {
      width: 125px;
      padding: 8px 12px 8px 6px;
      align-items: baseline;
      border: 1px solid #f2f2f2;
    }
  }
  .rpts {
    width: 220px;
  }

  .bin {
    width: 26px;
    display: block;
    margin: 0 auto;
    cursor: pointer;
  }
  .bin-h {
    width: 60px !important;
  }
}
