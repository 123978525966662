#exams-management {
  display: flex;
  flex-direction: column;
  padding: 1px 32px;

  .add-new-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }

}
