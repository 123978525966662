#reset-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;

  @media (width > 400px) {
    min-width: 315px;
  }

  .reset-password {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 30px;
    letter-spacing: -0.02em;
    color: #181c32;
  }

  .new-password,
  .confirm-new-password {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #474850;
    margin-top: 2rem;
    width: 100%;
    gap: 5px;
    display: flex;
    flex-direction: column;
  }

  .btn {
    width: 90px;
    margin-top: 3rem;
  }

  .btns-container {
    display: flex;
    //width: 100%;
    gap: 30px;
    //justify-content: space-around;
    button {
      width: 110px;
      height: 46px;
    }
  }
}
