#home {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: hidden;

  @media (width >= 500px) {
    margin: 15px 28px 5px 30px;
  }

  @media (width < 500px) {
    margin: 15px 5px 5px 5px;
  }
  gap: 23px;

  .widgets-container {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;

    @media (width <= 500px) {
      gap: 25px;
      padding: 0px 10px;
    }
  }

  .home-slider-wrapper{
    position: relative;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 2em auto;
  }

  .sign-out {
    cursor: pointer;
  }

  #widget-box, #calendar-widget {
    height: 243px;
    background-color: white;
  }

  #widget-box, #calendar-widget, .welcome-box {
    flex-grow: 1;

    @media (width >= 650px) {
      width: calc(50% - 20px);
    }
  }

  .subsubject-chart {
    display: flex;
    flex-direction: column;
    background-color: white;
    flex-grow: 1;

    .sub-title {
      margin: 20px;
      font-size: 15px;
      font-weight: 600;
    }
    .form-select {
      margin: 0 20px 10px 20px;
      width: 240px;
    }

    > div {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      
    }

    @media (width <= 650px) {
      //width: calc(100% - 15px);
      margin: 0 11px;
    }
  }

  .overtime-chart {
    display: flex;
    flex-direction: column;
    background: white;
    height: 400px;
    align-self: flex-start;
    border-radius: 10px;
    flex-grow: 1;

    .left-container {
      display: flex;
      height: 100%;
      .left-panel {
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0px 12px;
        width: 190px;

        span {
          margin: 5px 0;
          color: #7E8299;
          cursor: pointer;
          //padding: 20px 30px;
          height: 53px;
          display: flex;
          justify-content: center;
          align-items: center;

          &.selected {
            //color: red;
            padding: 0px 30px;
            background-color: #F3F6F9;
            border-radius: 15px;
          }
        }
      }
    }

    .labels-panel {
      display: flex;
      gap: 41px;
      position: absolute;
      padding-left: 42px;
      margin-top: -60px;
      z-index: 9999;

      span {
        color: white;
        width: 30px;
        text-align: center;

        &.zero-value {
          //background-color: #F3F6F9;
          color: #000;
          margin-top: 10px;
        }
      }
    }
  }

  .overtime-header {
    display: flex;
    justify-content: space-between;
    //gap: 30px/;
    padding: 10px 60px 1px 30px;

    .progress-title {
      font-weight: bold;
      font-size: 15px;
      margin-top: 12px;
    }

    .score-box {
      display: flex;
      gap: 30px;
    }

    .header-item {
      display: flex;
      flex-direction: column;

      span:nth-child(1) {
        color: #B5B5C3;
        font-weight: 600;
      }

      span:nth-child(2) {
        font-weight: bold;
        font-size: 15px;
      }
    }
  }

  .welcome-box {
    background-color: #0249FB;
    padding: 20px 25px;
    height: 243px;
    border-radius: 8px;

    span {
      color: white;
    }

    .title-welcome {
      display: flex;
      flex-direction: column;

      .first-line {
        font-weight: bold;
        font-size: 16px;
      }
      .second-line {
        font-size: 15px;
      }
    }
    .welcome-data {
      margin-top: 12px;
    }
  }

  .try-btn {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    margin-top: 37px;

    @media (500px <= width <= 868px) {
      margin-top: 16px;
    }

    @media (width <= 439px) {
      margin-top: 25px;
    }

    span {
      color: #0249FB;
      font-size: 15px;
      //line-height: 1.2;
      font-weight: 600;
      margin-right: 8px;
    }
  }

  .no-data {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &.bar-chart {
      //width: 260px;
      margin-top: 20px;
      padding-bottom: 20px;
      height: 70%;
    }
  }

  #donut-chart {
    @media (width >= 1400px) {
      width: 400px;
    }

    @media (width >= 1600px) {
      width: 500px;
    }

    @media (width <= 500px) {
      margin-left: auto;
      margin-right: auto;
    }

    @media (width <= 1075px) {
      flex-grow: 1;
    }
  }

 /*  .performance-box {
    @media (width <= 650px) {
      justify-content: center;
    }
  } */

  /* width */
  ::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
  }
}