#bar-chart {
  width: 420px;
  background: white;
  padding: 25px;

  .chart-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .title-span {
      position: absolute;
      margin-bottom: 32px;
      //top: 47.5%;
      //left: 40%;
      font-weight: 700;
      display: flex;
      flex-direction: column;
      font-size: 16px;
      text-align: center;
      line-height: 1.2;

      .main-number {
        font-size: 24px;
      }
    }
  }
  .text-performance {
    display: flex;
    flex-direction: column;
    padding:10px 30px;
    margin-bottom: 10px;
  }

  .first-line-performance,
  .first-line-ranking {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
  }

  .second-line-performance,
  .second-line-ranking {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    color: #b5b5c3;
  }
}
