#glacard{

  .plan-box-1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: baseline;
    padding: 20px 30px;
    margin-top: 3.5rem;
    width: 262px;
    height: 456px;

    background: #f2f2f2;
    box-shadow: 0px 41.9702px 33.9759px rgba(82, 67, 194, 0.295755);
    border-radius: 25.9816px;
  }

  .plan-box-1-line-1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 59px;
    color: #0249fb;
    display: flex;
    gap: 10px;
    align-items: center;

    .faded {
      text-decoration: line-through;
      color: rgba(76,78,100,.47);
      font-size: 24px;
      opacity: .7;
    }
  }

  .plan-box-1-line-2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 1.2;
    color: #0249fb;
  }

  .plan-box-1-text {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 4px;
  }

  .plan-box-text {
    display: flex;
    gap: 10px;
    //justify-content: center;
    align-items: center;
  }

  .plan-box-1-line-4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14.9894px;
    line-height: 22px;
    display: flex;
    align-items: center;

    color: #0249fb;
  }

  .plan-box-1-button {
    width: 233.36px;
    height: 44.1px;
    margin-top: 22px;

    @media (width <= 480px) {
      width: 45vw;
    }
  }

  .text-button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-align: center;

    color: #ffffff;
  }






}