#exam-creation {
  .main-content {
    display: flex;
    flex-direction: column;
    padding-left: 48px;
  }
  .form-check-custom {
    flex-grow: 1;
    margin-top: 25px;
    margin-left: 35px;
  }

  .image-upload-container {
    width: 100% !important;
    padding-left: 5px;

    .upload-img {
      cursor: pointer;
      padding-left: 5px;
    }

    .upload-box,
    .img-container {
      input[type='file']::file-selector-button {
        display: none;
      }

      input[type='file'] {
        font-size: 0;
      }
      ::file-selector-button {
        font-size: initial;
      }
    }
  }

  .uploaded-file {
    object-fit: contain;
    max-height: 400px;
    cursor: pointer;
  }

  header {
    width: 90%;
    //max-width: 800px;
  }

  .hello-text {
    align-self: flex-start;

    span {
      text-transform: capitalize;
    }
  }

  .update-profile-btn {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-top: -20px;
  }

  form,
  .box-password {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }

  .profile-data-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    //max-width: 800px;

    & > div {
      margin-bottom: 25px;
      width: calc(50% - 15px);
    }

    .first-row,
    .second-row {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .title-box {
      width: 70%;
    }

    .time-minutes-box {
      width: 27%;
    }
  }

  .box-profile,
  .box-password {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 18px 42px 18px 42px;
    width: calc(100% - 52px);
    max-width: 1200px;
    //height: 200px;
    //border: 1px solid grey;
    //background-color: #f1efec;
    background-color: white;
  }

  .text-profile,
  .text-password {
    margin-bottom: 36px;
  }

  .first-button-save,
  .second-button-save {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 36px;
  }

  .full-width {
    width: 100% !important;
  }

  @media only screen and (max-width: 700px) {
    .profile-data-container,
    .change-password-container {
      flex-direction: column;
    }
  }
}
