@import '../../../_variables.scss';

#subject-creation {
  margin: 6px 51px;

  .fa-exclamation {
    color: red;
    font-size: 17px;
    position: absolute;
    margin-left: 8px;
    //margin-bottom: 10px;
    margin-top: -2px;
    //top: 0;

    &.continue {
      //margin-top: -2px;
      //margin-left: 9px
      right: 12px;
    }
  }

  form {
    background: white;
    padding-bottom: 15px;
  }
  .header-box {
    width: calc(100%);
    //background: white;
    padding: 0 0 20px 0;
    display: flex;
    align-items: center;
    gap: 12px;

    .left-panel {
      span {
        font-weight: 600;
        font-size: 18px;
      }
    }

    .right-panel {
      margin-left: auto;
    }
  }

  .sub-suject-container {
    max-height: 210px;
    overflow: auto;
    margin-bottom: 9px;
  }

  .sub-suject-box {
    display: flex;
    align-items: center;
    background-color: white;
    margin-top: 10px;
    padding: 6px 5px 6px 16px;
    flex-wrap: wrap;
    //border-radius: 5px;
    gap: 10px;

    .title {
      text-transform: capitalize;
    }

    .number-box {
      background-color: #f6f6f6;
      padding: 6px 14px;
      border-radius: 8px;
      color: #20d489;
      cursor: pointer;
      &.has-errors {
        background-color: #e30c0c;
        color: white;
      }
      &.selected {
        background-color: #05ff004d;
        color: #20d489;
      }
    }
  }

  .add-question-container {
    width: 100%;
    display: flex;
    //justify-content: flex-end;
    //margin-left: 10px;
    margin-top: 10px;
  }

  .toggle-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .add-q-btn {
    padding: 6px 12px;
    background: #0249fb;
    cursor: pointer;
    color: white;
    border-radius: 8px;
  }

  .question-form {
    display: flex;
    background: white;
    gap: 50px;
    margin-top: 20px;

    .left-form {
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 25px;
      padding-top: 12px;
      gap: 10px;

      span,
      label {
        font-weight: 600;
        font-size: 13.5px;
        //margin-top: 10px;
      }

      .img-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 8px;

        &.selected {
          border: 1px solid #0249fb;
        }
      }

      .uploaded-file {
        object-fit: contain;
        max-height: 195px;
        width: 100%;
        //margin: 23px;
      }

      .form-switch {
        //margin-left: -12px;
        display: flex;
        align-items: center;
        margin: 10px 0 10px -45px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;

        #custom-switch {
          margin-left: 10px;
          margin-top: 2px;
        }
      }

      .form-check-label {
        /* margin-top: 11px;
        margin-left: 9px; */
      }

      .upload-box {
        background-color: white;
        height: 200px;
        background: #f9f9f9;
        display: flex;
        flex-direction: column;
        margin-top: 1px;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        position: relative;

        &.selected {
          border: 1px solid #0249fb;
        }

        .upload-img {
          width: 130px;
        }

        .upload-input {
          //margin-top: 15px;
          bottom: 0;
          right: -4px;
          position: absolute;
        }
      }
      .secondary {
        text-align: center;
        //margin-left: 50px;
        margin-bottom: 20px;
        margin-top: 10px;
        position: absolute;
        bottom: -20px;
        //scale: 0.68;
        right: -4px;
      }
    }

    .right-form {
      width: 55%;
      margin-top: 30px;
      padding-right: 30px;
      display: flex;
      flex-direction: column;

      .opts {
        font-weight: 700;
      }

      .options-container {
        max-height: 210px;
        overflow: auto;

        &.tiffin {
          max-height: 300px;
          flex-wrap: wrap;
          display: flex;
          flex-direction: column;
          overflow: none;
          align-content: flex-start;
          padding-top: 5px;

          .option-box {
            margin-left: 10px;
            width: auto;
          }
        }
      }

      .add-opt-btn {
        //height: 20px;
        padding: 4px 12px;
        width: 72px;
        //font-size: 13px;
        align-self: center;
      }

      .option-box {
        display: flex;
        align-items: center;
        //background: #F9F9F9;
        width: 100%;
        margin-bottom: 8px;
        //height: 25px;

        .form-check-input {
          margin-right: 6px;
        }

        .inner-box {
          display: flex;
          flex-grow: 1;

          input {
            //padding: 1px;
            height: 33px;
            min-height: 33px;
          }
        }

        .letter-div {
          padding: 0 10px;
          display: flex;
          width: 30px;
          justify-content: center;
          font-weight: 600;
          align-items: center;
          background: #f3f6f9;
          text-align: center;
          border-right: 1px solid #aeaeae;
        }
      }

      .combo-boxes {
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        gap: 15px;
      }
    }
  }

  .trash-box {
    //margin-left: 10px;
    font-size: 15px;
    cursor: pointer;
    padding: 6px 12px;
    display: flex;
    justify-content: center;
    border-radius: 7px;
    background-color: #c70a0a2e;
  }

  .order-select-box {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    margin-top: -10px;

    span {
      font-size: 14px;
    }

    .form-select {
      width: 60px;
      padding: 4px 10px;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 29px;
    //padding: 5px 28px 20px 28px;

    .right-btns {
      display: flex;
      gap: 14px;
    }

    .save-btn {
      padding: 6px 22px;

      &.cont {
        display: flex;
        align-items: center;
        position: relative;
      }
    }

    button {
      //line-height: 1.2;
      height: auto;
    }
  }

  .upload-box,
  .img-container {
    input[type='file']::file-selector-button {
      border: 2px solid #6c5ce7;
      //padding: 2px 5px;
      border-radius: 6px;
      width: 80px;
      font-size: 10px;
      cursor: pointer;
      //transition: 1s;
      color: #ffffff;
      background-color: #0249fb;
    }

    input[type='file'] {
      font-size: 0;
    }
    ::file-selector-button {
      font-size: initial;
    }
  }

  .upload-header-box {
    display: flex;
    align-items: center;
    margin-left: 32px;
    margin-top: 15px;
    font-weight: 600;
    font-size: 13.5px;
    gap: 7px;

    img {
      cursor: pointer;
    }

    input[type='file']::file-selector-button {
      display: none;
    }

    input {
      max-width: 100px;
      //display: none;
    }
  }

  #test-paste {
    opacity: 0;
  }
}
